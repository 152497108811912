import { DesktopOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Drawer, Layout, Menu, Row, Spin } from 'antd';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Logo from '../../../assets';
import { MODULES, ROUTES } from '../../../common/constants';
import { LIMIT } from '../../../common/utils';
import Folders from '../../../modules/workspace/Folders';
import {
  GET_WORKSPACE_DETAILS,
  GET_WORKSPACE_DETAILS_BYID,
} from '../../../modules/workspace/graphql/Queries';
import Workspace from '../../../modules/workspace/Workspace';
import UserProfile from './UserProfile';

const { Header } = Layout;
const { SubMenu } = Menu;

const AppHeader = ({ location: { pathname } }) => {
  const history = useHistory();
  const { getWorkspace, setWorkspace } = useContext(AppContext);
  const id = getWorkspace();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState(false);
  const [workSpaceName, setWorkSpaceName] = useState(null);
  const [selectedKey, setSelectedKey] = useState(['SELECTED_WORKSPACE']);
  const [workSpaceData, setWorkSpaceData] = useState();

  const [getData] = useLazyQuery(GET_WORKSPACE_DETAILS_BYID, {
    onCompleted(res) {
      const { getWorkspaceById: { name = '' } = {} } = res;
      setWorkSpaceName(name);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (id) {
      getData({
        variables: {
          workspaceId: id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [getWorkSpaceDetail, { loading }] = useLazyQuery(
    GET_WORKSPACE_DETAILS,
    {
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      },
      onCompleted(res) {
        setWorkSpaceData([...res?.workspace?.data]);
      },
      onError() {},
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    getWorkSpaceDetail({
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname?.split('/')[1] === 'workspace') {
      setSelectedKey([ROUTES?.WORKSPACE]);
    } else if (pathname?.includes('folder')) {
      setSelectedKey([pathname]);
    } else if (pathname?.includes('profile')) {
      setSelectedKey([pathname]);
    } else {
      setSelectedKey([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onChange = (text) => {
    setVisible(!visible);
    setPlacement(text);
  };

  const onMenuSelect = (e) => {
    const config = get(e, 'item.props.config', {});
    setSelectedKey(e.key);
    history.push(`${e.key}`);
    if (config.type === 'WORKSPACE' && config.workspaceId) {
      setWorkspace(config?.workspaceId);
    }
  };
  function getItem({ label, key, icon, children, type, config = {} }) {
    return {
      key,
      icon,
      children,
      label,
      type,
      config,
    };
  }
  const menuItems = [
    id &&
      getItem({
        label: MODULES?.WORKSPACE_MANAGEMENT,
        key: ROUTES?.WORKSPACE,
      }),
    getItem({
      label: workSpaceName || 'Select Workspace',
      key: 'SELECTED_WORKSPACE',
      icon: !id && <DesktopOutlined />,
      children: workSpaceData?.map(
        (workspace) =>
          getItem({
            label: workspace?.name,
            key: `/${workspace?.defaultFolder}${ROUTES.FOLDER}`,
            config: { type: 'WORKSPACE', workspaceId: workspace?.id },
          }) || [],
      ),
    }),
    getItem({
      label: <UserProfile />,
      key: ROUTES.USERS_PROFILE,
      children: [
        getItem({
          label: MODULES?.USERS_MANAGEMENT,
          key: ROUTES.USERS_PROFILE,
        }),
        getItem({
          label: 'Logout',
          key: ROUTES?.LOGOUT,
        }),
      ],
    }),
  ];
  return (
    <>
      <Header>
        <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg">
          <div className="d-flex align-center">
            {pathname !== ROUTES.MAIN && (
              <Row>
                <Col>
                  <Button
                    onClick={() => onChange('left')}
                    className="btn-back fontsize-22 hamburger"
                    type="text"
                  >
                    <MenuOutlined />
                  </Button>
                </Col>
                <Col />
              </Row>
            )}

            <Link to="/" className="gx-site-logo ml-10 center-logo">
              <Row align="middle">
                <Col span={4}>
                  <Logo className="logo-icon" />
                </Col>
              </Row>
            </Link>
          </div>
        </div>
        <Row>
          <Col>
            <Button
              onClick={() => onChange('right')}
              className="btn-back fontsize-22 hamburger"
              type="text"
            >
              <MoreOutlined />
            </Button>
          </Col>
        </Row>
        <div className="header-notification header-menu">
          <Menu
            className="header-menu"
            theme="light"
            mode="horizontal"
            selectedKeys={selectedKey}
            items={menuItems}
            onSelect={onMenuSelect}
            triggerSubMenuAction="click"
          />
        </div>
      </Header>

      <Drawer
        placement={placement}
        closable
        onClose={onChange}
        open={visible}
        title={
          <Link to="/" className="gx-site-logo">
            <Row align="middle">
              <Col span={4}>
                <Logo className="logo-icon" />
              </Col>
            </Row>
          </Link>
        }
      >
        {placement === 'left' ? (
          <Folders />
        ) : (
          <div className="header-notification">
            <Menu theme="lite" className="sidebar-menu">
              {pathname !== ROUTES.MAIN && (
                <Menu.Item
                  className="d-flex align-center mt-30 mb-0"
                  onClick={() => history?.push(ROUTES?.WORKSPACE)}
                >
                  <span className="sub-title fontsize-18">
                    {MODULES?.WORKSPACE_MANAGEMENT}
                  </span>
                </Menu.Item>
              )}
              {pathname !== ROUTES.MAIN && (
                <Menu.Item
                  className="d-flex align-center"
                  onClick={() => history?.push(ROUTES?.USERS_PROFILE)}
                >
                  <span className="sub-title fontsize-18">
                    {MODULES?.USERS_MANAGEMENT}
                  </span>
                </Menu.Item>
              )}
              <SubMenu
                className="d-flex align-center"
                key="SubMenu"
                title={
                  <Row justify="space-between" align="middle">
                    <Col>
                      <span className="sub-title fontsize-18">
                        {workSpaceName || 'Select Workspace'}
                      </span>
                    </Col>
                  </Row>
                }
              >
                <Spin spinning={loading} className="pl-50 ">
                  <Workspace hidden Buttontype="link" />
                </Spin>
              </SubMenu>
              <Menu.Item className="d-flex align-center ">
                <span className="sub-title fontsize-18">
                  <Link to={ROUTES?.LOGOUT}>Logout</Link>
                </span>
              </Menu.Item>
            </Menu>
          </div>
        )}
      </Drawer>
    </>
  );
};
export default withRouter(AppHeader);

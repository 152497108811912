import { UserOutlined } from '@ant-design/icons';
import { Avatar, Form, Input } from 'antd';
import React from 'react';
import { formValidatorRules } from '../common/utils';

const { required } = formValidatorRules;

const CommonWorkspaceForm = ({
  fileChange,
  inputChange,
  logo,
  workspaceName,
  onFinish,
  data,
  setDisableButton,
}) => (
  <div>
    <Form
      onFinish={onFinish}
      form={data}
      name="basic"
      initialValues={{
        remember: true,
      }}
      layout="vertical"
      onValuesChange={() => setDisableButton(false)}
    >
      <Form.Item name="workspacelogo" className="mb-0">
        <center>
          <label htmlFor="photo-upload" className="custom-file-upload fas">
            <div className="img-upload">
              <div className="upload-text">+ Upload</div>
              {logo ? (
                <img className="profile-image" src={logo} alt="avatar" />
              ) : (
                <Avatar size={100} icon={<UserOutlined />} />
              )}
            </div>
            <input
              id="photo-upload"
              type="file"
              className="d-none"
              onChange={fileChange}
              accept="image/png, image/jpeg, image/jpg"
            />
          </label>
        </center>
      </Form.Item>
      <Form.Item
        label="Workspace Name"
        name="workspace"
        initialValue={workspaceName || ''}
        rules={[required]}
      >
        <Input placeholder="Workspace Name" onChange={inputChange} />
      </Form.Item>
    </Form>
  </div>
);

export default CommonWorkspaceForm;

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { firstName, lastName, profileImage } = getCurrentUser();

  return (
    <Space className="pointer">
      {profileImage && <Avatar src={profileImage} alt="profile" />}
      {firstName && lastName && !profileImage && (
        <Avatar>
          {firstName?.charAt(0)?.toUpperCase()}
          {lastName?.charAt(0)?.toUpperCase()}
        </Avatar>
      )}
      {!firstName && !lastName && !profileImage && (
        <Avatar icon={<UserOutlined />} />
      )}
    </Space>
  );
};

export default UserProfile;

import { Col, Row } from 'antd';
import React from 'react';
import BrowserData from './BrowserData';
import ClicksChart from './ClicksChart';
import DeviceData from './DeviceData';
import OsData from './OsData';
import RegionData from './RegionData';

const AnalyticsCard = () => (
  <>
    <Row gutter={[24, 12]} className="mt-30">
      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={4}>
        <ClicksChart hide />
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={4}>
        <BrowserData hide />
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={4}>
        <DeviceData hide />
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={4}>
        <RegionData hide />
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={4}>
        <OsData hide />
      </Col>
    </Row>
  </>
);

export default AnalyticsCard;

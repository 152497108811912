/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  VERIFY_USER: '/verify-user',
  AUTHENTICATION: '/authentication',
  USERS_PROFILE: '/user-profile',
  WORKSPACE: '/workspace',
  LINK: '/link',
  INVITEMEMBER: '/invitemember',
  FOLDER: '/folder',
  EDIT: '/edit',
  ANALYTICS: '/analytics',
  ERROR: '/error',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'User Profile',
  WORKSPACE_MANAGEMENT: 'Manage Workspace',
  INVITE_MEMBER: 'Invite Member',
  ALL_LINKS: 'All Links',
  FOLDERS: 'Folders',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const WORKSPACE = 'WORKSPACE';
export const HAS_OTP = 'HAS_OTP';
export const EMAIL = 'EMAIL';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png'];

/* Date and time */
export const defaultDateFormat = 'YYYY/MM/DD';

export const REGEX = {
  NAME: /^[a-zA-Z\s():;`~'",._\-[\]]*$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
};

/* Break points  */
export const SMALL_TABLET = 1000;
export const TABLET_BREAKPOINT = 1200;
export const BIG_SCREEN = 1600;

/* Terms and condition link */
export const TERMS_AND_SERVICES = 'https://utags.co/terms-and-condition';

import React, { useContext, useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';
import App from './app/App';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import Error404 from './Error404';
import history from './historyData';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import VerifyUser from './modules/auth/VerifyUser';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const { initializeAuth, dispatch } = useContext(AppContext);

  const updateWidth = () => {
    // eslint-disable-next-line no-undef
    dispatch({ type: 'SET_WIDTH', data: window?.innerWidth });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateWidth);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateWidth);
  });

  useEffect(() => {
    initializeAuth();
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path={ROUTES?.LOGOUT} component={Logout} />
        <PublicRoute exact path={ROUTES?.LOGIN} component={Login} />
        <PublicRoute exact path={ROUTES?.VERIFY_USER} component={VerifyUser} />
        <PublicRoute exact path={ROUTES?.ERROR} component={Error404} />
        <PrivateRoute
          exact
          path={ROUTES?.AUTHENTICATION}
          component={RefreshToken}
        />
        <PrivateRoute path={ROUTES?.MAIN} component={App} />
      </Switch>
    </Router>
  );
};
export default Routes;

import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, Menu, message, Modal, Row, Spin, Typography } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { LIMIT } from '../../common/utils';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import CommonWorkspaceForm from '../../components/CommonWorkspaceForm';
import { GET_SIGNED_URL } from '../users/graphql/Mutations';
import { CREATE_WORKSPACE, UPDATE_WORKSPACE } from './graphql/Mutations';
import {
  GET_WORKSPACE_DETAILS,
  GET_WORKSPACE_DETAILS_BYID,
} from './graphql/Queries';

const { Title } = Typography;

const Workspace = ({ hide, hidden, Buttontype }) => {
  const [form] = Form?.useForm();
  const history = useHistory();

  const {
    setWorkspace,
    state: { currentWorkspace },
    getCurrentUser,
    setPermission,
  } = useContext(AppContext);

  const { userId } = getCurrentUser();
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [currentFile, setCurrentFile] = useState('');
  const [filePreview, setfilePreview] = useState();
  const [workspaceData, setWorkspaceData] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [menuLoading, setMenuLoading] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([currentWorkspace]);
  const [workSpaceLoading, setWorkSpaceLoading] = useState(false);
  const [workSpaceData, setWorkSpaceData] = useState({});
  const { id: paramsID } = useParams();

  const [getData] = useLazyQuery(GET_WORKSPACE_DETAILS_BYID, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const { getWorkspaceById: { id } = {} } = res;
      setWorkSpaceData(res?.getWorkspaceById);
      setSelectedKeys(id);
      setWorkSpaceLoading(false);
    },
    onError() {
      setWorkSpaceLoading(false);
    },
  });

  const [getWorkSpaceDetail] = useLazyQuery(GET_WORKSPACE_DETAILS, {
    variables: {
      filter: {
        skip,
        limit: LIMIT,
      },
    },
    onCompleted(res) {
      setCount(res?.workspace?.count);
      setWorkspaceData([...res?.workspace?.data]);
      setMenuLoading(false);
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getWorkSpaceDetail({
      variables: {
        filter: {
          skip,
          limit: LIMIT,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paramsID) {
      setWorkSpaceLoading(true);
      getData({
        variables: {
          workspaceId: paramsID,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsID]);

  useEffect(() => {
    form?.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE, {
    onCompleted() {
      getWorkSpaceDetail({
        variables: {
          filter: {
            skip,
            limit: LIMIT,
          },
        },
      });
      history?.push(ROUTES?.WORKSPACE);
    },
    onError() {},
  });

  const [createWorkspace, { loading: createLoading }] = useMutation(
    CREATE_WORKSPACE,
    {
      onCompleted(data) {
        const {
          createWorkspace: {
            workspace: { id = '', defaultFolder = '', permission = '' } = {},
          } = {},
        } = data;
        setModalVisible(false);
        setPermission(permission);
        setSelectedKeys(id);
        setWorkspace(id);
        getWorkSpaceDetail({
          variables: {
            filter: {
              skip,
              limit: LIMIT,
            },
          },
        });
        history?.push(`/${defaultFolder}${ROUTES?.FOLDER}`);
      },
      onError() {},
    },
  );
  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    async onCompleted(data) {
      const { getSignedUrl: { signedURL = '', fileURL = '' } = {} } = data;

      const options = {
        headers: {
          'Content-Type': currentFile?.type,
          'Access-Control-Allow-Origin': 'Origin',
          'Access-Control-Allow-Methods': 'PUT',
          'x-amz-acl': 'public-read',
        },
      };

      const uploadFileOnS3 = await axios.put(signedURL, currentFile, options);
      if (uploadFileOnS3?.status === 200) {
        if (paramsID && workspaceName) {
          updateWorkspace({
            variables: {
              id: paramsID,
              logo: fileURL,
              name: workspaceName,
            },
          });
        } else if (paramsID) {
          updateWorkspace({
            variables: {
              id: paramsID,
              logo: fileURL,
            },
          });
        } else {
          createWorkspace({
            variables: { logo: fileURL, name: workspaceName },
          });
        }
      }
    },
    onError() {},
  });

  const handleOkButton = () => {
    if (currentFile) {
      const { name, type } = currentFile;
      getSignedURL({
        variables: {
          fileName: name,
          fileType: type,
        },
      });
    } else if (paramsID && workspaceName) {
      updateWorkspace({
        variables: {
          id: paramsID,
          name: workspaceName,
        },
      });
    } else {
      createWorkspace({
        variables: { name: workspaceName },
      });
    }
    setDisableButton(true);
  };

  const handleChange = (e) => {
    if (e?.target?.files?.length > 0) {
      if (
        e?.target?.files?.[0]?.type === 'image/jpeg' ||
        e?.target?.files?.[0]?.type === 'image/png' ||
        e?.target?.files?.[0]?.type === 'image/jpg'
      ) {
        setCurrentFile(e?.target?.files?.[0]);
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        const file = e?.target?.files?.[0];
        reader.onloadend = () => {
          setfilePreview(reader?.result);
        };
        reader?.readAsDataURL(file);
      } else {
        message?.error(`${e?.target?.files?.[0]?.name} is not valid type.`);
      }
    }
  };

  const handleInputChange = (e) => {
    setWorkspaceName(e?.target?.value);
  };

  const onClick = ({ id, defaultFolder, permission }) => {
    setPermission(permission);
    setMenuLoading(true);
    setWorkspace(id);
    setSelectedKeys(id);
    history?.push(`/${defaultFolder}${ROUTES?.FOLDER}`);
    setMenuLoading(false);
  };

  const onCancel = () => {
    setfilePreview();
    setDisableButton(true);
    setModalVisible(false);
    setCurrentFile();
  };

  const handleGoBack = () => {
    setDisableButton(true);
    history?.goBack();
  };

  if (paramsID) {
    return (
      <Row justify="center">
        <Col span={15}>
          <Card
            loading={workSpaceLoading}
            className="mb-20"
            title={
              <>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Button
                      onClick={() => handleGoBack()}
                      className="btn-back"
                      type="text"
                    >
                      <LeftOutlined title="Back" />
                    </Button>
                    <span className="ml-5"> Update workspace</span>
                  </Col>
                </Row>
              </>
            }
            bordered={false}
          >
            <CommonWorkspaceForm
              fileChange={handleChange}
              inputChange={handleInputChange}
              workspaceName={workSpaceData?.name}
              logo={filePreview || workSpaceData?.logo}
              setDisableButton={setDisableButton}
            />

            <Button
              className="btn-Primary btnsave"
              onClick={handleOkButton}
              type="primary"
              htmlType="submit"
              disabled={disableButton}
            >
              Save
            </Button>
            <Button
              type="primary "
              className="btn-secondary-xl btnsave"
              onClick={() => history?.push(ROUTES?.WORKSPACE)}
            >
              Cancel
            </Button>
          </Card>
        </Col>
      </Row>
    );
  }

  const handleInfiniteScroll = (e) => {
    const element = e?.target;
    if (
      element?.scrollTop + element?.offsetHeight >= element?.scrollHeight - 5 &&
      skip + LIMIT < count
    ) {
      setSkip(skip + LIMIT);
    }
  };

  return (
    <>
      <Row justify="space-around" align="middle" className="nav-row">
        <Col hidden={hidden}>
          <Title level={3}>Select your workspace</Title>
          <Title level={5}>
            Please select a workspace from the below or create a new workspace
          </Title>
        </Col>
        <Col>
          <Spin spinning={menuLoading}>
            <Menu
              mode="inline"
              className={`sidebar-menu  ${hidden ? 'workspace-data' : ''}`}
              onScroll={(e) => handleInfiniteScroll(e)}
              selectedKeys={selectedKeys}
            >
              {workspaceData
                ?.filter(
                  (record) =>
                    userId === parseInt(record.user?.id, 10) ||
                    (userId !== parseInt(record.user?.id, 10) &&
                      !record.archived),
                )
                .map(
                  ({
                    id = '',
                    name = '',
                    defaultFolder = '',
                    permission = '',
                  }) =>
                    !hide ? (
                      <Menu.Item key={id}>
                        <p
                          onClick={() =>
                            onClick({
                              id,
                              defaultFolder,
                              permission,
                            })
                          }
                        >
                          {name}
                        </p>
                      </Menu.Item>
                    ) : null,
                )}
            </Menu>
            <Button
              type={Buttontype || 'primary'}
              className="btn-Primary"
              icon={Buttontype ? <PlusCircleOutlined /> : ''}
              onClick={() => setModalVisible(true)}
            >
              Add Workspace
            </Button>
          </Spin>
        </Col>
      </Row>

      <Modal
        title="Create a New Workspace"
        centered
        open={modalVisible}
        onOk={form?.submit}
        onCancel={onCancel}
        okButtonProps={{
          loading: createLoading,
          className: 'btn-Primary',
          disabled: disableButton,
        }}
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <CommonWorkspaceForm
          fileChange={handleChange}
          inputChange={handleInputChange}
          onFinish={handleOkButton}
          data={form}
          logo={filePreview}
          setDisableButton={setDisableButton}
        />
      </Modal>
    </>
  );
};

export default Workspace;

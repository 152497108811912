import { SettingOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Progress, Table } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { percentformat } from '../../common/utils';
import Card from '../../components/Card/card';
import CommonAnalyticsCard from '../../components/CommonAnalyticsCard';
import { GET_OS } from './graphql/Queries';

const OsData = ({ hide, hidden }) => {
  const { id } = useParams();
  const { getStartDate, getEndDate } = useContext(AppContext);
  const startDate = getStartDate();
  const endDate = getEndDate();
  const { data = {}, loading } = useQuery(GET_OS, {
    fetchPolicy: 'network-only',
    variables: {
      linkId: id,
      startDate: startDate || '',
      endDate: endDate || '',
    },
  });

  const {
    getOperatingSystem: {
      count = '',
      operatingSystem = [],
      totalCount = '',
    } = {},
  } = data;

  const columns = [
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      width: '30%',
    },
    {
      title: 'Visit',
      dataIndex: 'visit',
      key: 'visit',
      width: '25%',
      align: 'center',
    },
    {
      title: 'Visit %',
      dataIndex: 'visitpercent',
      key: 'visitpercent',
      render: (text) => (
        <div className="width-percent-95">
          <Progress
            percent={percentformat(text, totalCount)}
            size="small"
            status="normal"
            strokeColor={{
              '0%': '#e27969',
              '100%': '#303179',
            }}
          />
        </div>
      ),
    },
  ];

  const userdata = operatingSystem.map(
    ({ os = '', count: clickCount = '' }) => ({
      key: os,
      os,
      visit: clickCount,
      visitpercent: clickCount,
    }),
  );

  return (
    <>
      <CommonAnalyticsCard
        loading={loading}
        label="OS"
        count={count}
        hidden={hidden}
      >
        <SettingOutlined className="fontsize-28 orange_col" />
      </CommonAnalyticsCard>

      <div hidden={hide}>
        <Card title={`Operating System: ${count}`}>
          <Table
            dataSource={userdata}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </Card>
      </div>
    </>
  );
};

export default OsData;

import { Col, Row } from 'antd';
import React from 'react';
import Card from './Card/card';

const CommonAnalyticsCard = ({ hidden, loading, count, label, children }) => (
  <>
    <div hidden={hidden}>
      <Card loading={loading} hoverable bordered>
        <Row gutter={24}>
          <Col className="ant-input-prefix" span={10}>
            {children}
          </Col>
          <Col span={14} className="fontsize-18 maxCol-width">
            <Row>{label}</Row>
            <Row>
              <b className="link-icon mt-10"> {count}</b>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  </>
);

export default CommonAnalyticsCard;

import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { REGEX } from '../common/constants';
import {
  copyToClipboard,
  formValidatorRules,
  initialTableFilter,
} from '../common/utils';
import { CREATE_LINK } from '../modules/Link/graphql/Mutations';

const { Option } = Select;
const { required } = formValidatorRules;
function NewLinkModal({ refetchLinks, fromAllLinks = false }) {
  const {
    state: { filterData, folders },
    getWorkspace,
    getPermission,
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const { id } = useParams();
  const workspaceId = getWorkspace();
  const permission = getPermission();
  const [URL, setURL] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [createLink, { loading }] = useMutation(CREATE_LINK, {
    onError() {},
  });

  useEffect(() => {
    form?.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const onFinish = async (values) => {
    if (URL) {
      setModalVisible(false);
      refetchLinks({
        variables: {
          input: fromAllLinks ? { workspaceId } : { folderId: id },
          filter: { ...initialTableFilter, tags: filterData },
        },
      });
      setURL(null);
      return;
    }
    const {
      url,
      utmCampaign,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent,
      folderId,
    } = values;
    const input = {
      URL: url,
      utmCampaign,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent,
      folderId: fromAllLinks && folderId ? folderId : id,
      workspaceId,
    };
    const response = await createLink({
      variables: {
        input,
      },
    });
    if (response?.data?.createLink) {
      const { shortLink } = response?.data?.createLink;
      setURL(shortLink);
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onCancel = () => {
    if (URL) {
      refetchLinks({
        variables: {
          input: fromAllLinks ? { workspaceId } : { folderId: id },
          filter: {
            skip: 0,
            limit: 10,
            tags: filterData,
          },
        },
      });
      setURL(null);
    }
    setModalVisible(false);
  };

  return (
    <div>
      <Button
        disabled={permission === 'READ_ONLY'}
        type="primary"
        htmlType="submit"
        onClick={() => setModalVisible(true)}
      >
        Create a new link
      </Button>
      <Modal
        title="Create a new link"
        centered
        open={modalVisible}
        onOk={form?.submit}
        onCancel={() => onCancel()}
        className="add-link-modal"
        auto
        okButtonProps={{
          loading,
          className: 'btn-Primary',
        }}
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Enter URL"
            name="url"
            rules={[
              {
                required: true,
                pattern: REGEX?.WEB_URL,
                message: 'Enter valid URL',
              },
            ]}
          >
            <Input placeholder="https://example.com" />
          </Form.Item>
          <Form.Item label="Campaign" name="utmCampaign">
            <Input placeholder="summer_sale" />
          </Form.Item>
          <Form.Item label="Medium" name="utmMedium">
            <Input placeholder="email" />
          </Form.Item>

          <Form.Item label="Source" name="utmSource">
            <Input placeholder="newsletter" />
          </Form.Item>
          <Form.Item label="Content" name="utmContent">
            <Input placeholder="banner_ad" />
          </Form.Item>

          <Form.Item label="Term" name="utmTerm">
            <Input placeholder="discount_coupon" />
          </Form.Item>

          {fromAllLinks && (
            <Form.Item
              label="Folder"
              name="folderId"
              className="mb-0"
              rules={[required]}
            >
              <Select placeholder="Default">
                {folders.map((folder) => (
                  <Option key={folder?.id} value={folder?.id}>
                    {folder?.folderName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {URL && (
            <div className="mt-20">
              <span className="d-flex justify-center align-center shorten-url-labels ">
                Copy below link
              </span>
              <div className="shorten-url-section d-flex justify-between align-center mt-10">
                <span>
                  <a
                    href={`${process.env?.REACT_APP_GRAPHQL}${URL}`}
                    target="blank"
                  >{`${process.env?.REACT_APP_GRAPHQL}${URL}`}</a>
                </span>
                <Button
                  onClick={() => {
                    copyToClipboard(`${process.env?.REACT_APP_GRAPHQL}${URL}`);
                    message?.success('Link copied to clipboard..!');
                  }}
                  type="primary"
                >
                  Copy
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
}

export default NewLinkModal;

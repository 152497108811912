import isFunction from 'lodash/isFunction';
import moment from 'moment';
import { defaultDateFormat, REGEX } from './constants';

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window?.dispatchEvent(new Event('refresh-grid'));
};

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (datetime && moment && format) {
    return moment(datetime)?.format(format);
  }

  return datetime;
};

export const percentformat = (count, totalCount) =>
  Math?.abs((count / totalCount) * 100)?.toFixed(count === totalCount ? 0 : 2);

export const formValidatorRules = {
  required: {
    required: true,
    message: 'This field is required',
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!',
  },
  Name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('should not contain the special characters.');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
};

export const copyToClipboard = async (text, cb) => {
  try {
    // eslint-disable-next-line no-undef
    await navigator.clipboard.writeText(text);
    if (isFunction(cb)) cb();
  } catch (err) {
    if (isFunction(cb)) cb(err);
  }
};

export default (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match?.[1]}) ${match?.[2]}-${match?.[3]}`;
  }

  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

export const initialTableFilter = {
  skip: 0,
  limit: 10,
};

export const downloadQR = (canvasId) => {
  /* eslint-disable no-undef */
  const svg = document.getElementById(canvasId);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const svgData = new XMLSerializer().serializeToString(svg);

  const img = new Image();
  const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
  const url = URL.createObjectURL(svgBlob);

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    URL.revokeObjectURL(url);

    const pngUrl = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  img.src = url;
};

export const initialPaginationValue = {
  total: 0,
  current: 1,
};

export const LIMIT = 20;

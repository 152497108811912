import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import {
  EMAIL,
  HAS_OTP,
  REFRESH_TOKEN,
  TOKEN,
  USER,
  WORKSPACE,
} from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage?.getItem(USER);
  loggedInUser = loggedInUser ? JSON?.parse(loggedInUser) : null;

  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  email: '',
  refreshToken: '',
  currentWorkspace: '',
  permission: '',
  startDate: '',
  endDate: '',
  filterData: [],
  folders: [],
  // eslint-disable-next-line no-undef
  windowWidth: window.innerWidth,
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};

      // eslint-disable-next-line no-undef
      localStorage?.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action?.data };
    case 'SET_CURRENT_WORKSPACE':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(WORKSPACE, action?.data);
      return { ...state, currentWorkspace: action?.data };
    case 'SET_PERMISSION':
      return { ...state, permission: action?.data };
    case 'SET_START_DATE':
      return { ...state, startDate: action?.data };
    case 'SET_FOLDERS':
      return { ...state, folders: action?.data };
    case 'SET_END_DATE':
      return { ...state, endDate: action?.data };
    case 'SET_WIDTH':
      return { ...state, windowWidth: action?.data };
    case 'SET_OTP_STATUS':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(HAS_OTP, action?.data);
      return { ...state };
    case 'SET_USER_EMAIL':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(EMAIL, action?.data);
      return { ...state };
    case 'LOGOUT':
      delete api.defaults.headers.common.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();

      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(REFRESH_TOKEN, action.data);
      return { ...state, refreshToken: action?.data };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action?.data };
    case 'SET_FILTER_TAG':
      return { ...state, filterData: action?.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () =>
    // eslint-disable-next-line no-undef
    localStorage.getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON.parse(localStorage?.getItem(USER))
      : {};

  const getOtpStatus = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(HAS_OTP) || false;

  const getUserEmail = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(EMAIL) || false;

  const removeSignupData = () => {
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(HAS_OTP);
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(EMAIL);
  };

  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return (loggedInUser?.roles && loggedInUser?.roles?.[0]) || '';
  };

  const isAuthenticated = () => state.authenticated;

  const initializeAuth = (authToken, user) => {
    const token = authToken || getToken();

    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      const userData = {
        userId: parseInt(user?.id, 10),
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        profileImage: user?.profileImage,
        refreshToken: user?.refreshToken,
        message: user?.messages,
        lastLoggedIn: user?.lastLoggedIn,
      };
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: userData });
      if (userData?.roles?.[0]) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: userData?.roles?.[0] });
      }
      if (user?.refreshToken) {
        dispatch({ type: 'SET_REFRESH_TOKEN', data: user?.refreshToken });
      }
    }
  };

  const setWorkspace = (workspaceId = '') => {
    if (workspaceId) {
      dispatch({ type: 'SET_CURRENT_WORKSPACE', data: workspaceId });
    }
  };

  const getWorkspace = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(WORKSPACE) || null;
  const setPermission = (permission = '') => {
    if (permission) {
      dispatch({ type: 'SET_PERMISSION', data: permission });
    }
  };

  const getPermission = () => state?.permission;
  const setStartDate = (startDate = '') => {
    dispatch({ type: 'SET_START_DATE', data: startDate });
  };
  const getStartDate = () => state?.startDate;
  const setEndDate = (endDate = '') => {
    dispatch({ type: 'SET_END_DATE', data: endDate });
  };
  const getEndDate = () => state?.endDate;

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser,
    setWorkspace,
    getWorkspace,
    setPermission,
    getPermission,
    setStartDate,
    setEndDate,
    getStartDate,
    getEndDate,
    getOtpStatus,
    removeSignupData,
    getUserEmail,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };

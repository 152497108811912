import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Profile from './Profile';

const UserWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.USERS_PROFILE} component={Profile} />
  </Switch>
);

export default UserWrapper;

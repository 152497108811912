import { Form, Input } from 'antd';
import React from 'react';

const CommonFolderForm = ({
  inputChange,
  name,
  form,
  onFinish,
  setDisableButton,
}) => (
  <Form
    onFinish={onFinish}
    form={form}
    name="basic"
    initialValues={{
      remember: true,
    }}
    layout="vertical"
    onValuesChange={() => setDisableButton(false)}
  >
    <Form.Item
      className="mb-0"
      label="Folder Name"
      initialValue={name}
      name="foldername"
      rules={[
        {
          required: true,
          whitespace: true,
          message: 'Folder Name is required',
        },
      ]}
    >
      <Input placeholder="Folder Name" onChange={inputChange} />
    </Form.Item>
  </Form>
);

export default CommonFolderForm;

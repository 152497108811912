import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { AppContext } from '../../AppContext';
import Logo from '../../assets';
import { ROUTES } from '../../common/constants';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import { VERIFY_USER } from './graphql/Mutations';

const VerifyUser = (props) => {
  let count = 0;
  const {
    initializeAuth,
    removeSignupData,
    dispatch,
    getUserEmail,
  } = useContext(AppContext);
  const [otpstate, setOtp] = useState();

  useEffect(() => {
    dispatch({ type: 'SET_OTP_STATUS', data: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [verifyUser, { loading }] = useMutation(VERIFY_USER, {
    onCompleted(data) {
      const {
        verifyUser: { token, user },
      } = data;

      initializeAuth(token, user);
      removeSignupData();
      // eslint-disable-next-line react/destructuring-assignment
      props?.history?.push(ROUTES?.MAIN);
    },
    onError() {
      count += 1;
      if (count > 5) {
        // eslint-disable-next-line react/destructuring-assignment
        props?.history?.push(ROUTES?.LOGIN);
      }
    },
  });
  const handleChange = (otp) => {
    setOtp(otp);
  };

  const verifyOtpHandler = () => {
    verifyUser({
      variables: {
        email: getUserEmail(),
        otp: otpstate || '',
      },
    });
  };
  return (
    <div className="gx-login-container">
      <Card className="auth-card login-card center-element">
        <Row align="middle" justify="center" className="mb-20">
          <Col>
            <Logo className="logo-icon" />
          </Col>
        </Row>
        <Row justify="center" className="mb-10">
          <Col>
            <p className="auth-text">
              A OTP has been sent to your registered email. <br />
              Enter the OTP in the below to verify
            </p>
          </Col>
        </Row>

        <div className="otp-div">
          <OtpInput
            value={otpstate}
            onChange={handleChange}
            numInputs={6}
            renderSeparator={<span className="separator">-</span>}
            renderInput={(rest) => <input {...rest} />}
          />
        </div>
        <div>
          <Button
            type="primary"
            className="btn-Primary"
            onClick={verifyOtpHandler}
            loading={loading}
          >
            Verify OTP
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default VerifyUser;

import { RocketOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Card from '../../components/Card/card';
import CommonAnalyticsCard from '../../components/CommonAnalyticsCard';
import { GET_CLICKS } from './graphql/Queries';

const ClicksChart = ({ hide, hidden }) => {
  const { id } = useParams();
  const { getStartDate, getEndDate } = useContext(AppContext);
  const startDate = getStartDate();
  const endDate = getEndDate();
  const { data: ClickData = {}, loading } = useQuery(GET_CLICKS, {
    fetchPolicy: 'network-only',
    variables: {
      linkId: id,
      startDate: startDate || '',
      endDate: endDate || '',
    },
  });

  const { getClicks: { clicks = '', monthWise = [] } = {} } = ClickData;
  const data = monthWise.map(({ clicks: click, month }) => ({
    month,
    clicks: click,
  }));

  const SemanticBrand = '#dd7161';
  const config = {
    data,
    xField: 'month',
    yField: 'clicks',
    style: {
      fill: SemanticBrand,
      maxWidth: 30,
    },

    yAxis: {
      title: { text: 'No. of Clicks' },
    },
  };
  return (
    <>
      <CommonAnalyticsCard
        loading={loading}
        label="Clicks"
        count={clicks}
        hidden={hidden}
      >
        <RocketOutlined className="fontsize-28 orange_col" />
      </CommonAnalyticsCard>

      <div hidden={hide}>
        <Card loading={loading} title="Clicks" className="mt-30">
          <Column {...config} />
        </Card>
      </div>
    </>
  );
};

export default ClicksChart;

import { useContext } from 'react';
import { AppContext } from '../../AppContext';

const Logout = () => {
  const { dispatch } = useContext(AppContext);

  dispatch({ type: 'LOGOUT' });
  // eslint-disable-next-line no-undef
  window.location = '/login';
  return null;
};

export default Logout;

import { DesktopOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Progress, Table } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { percentformat } from '../../common/utils';
import Card from '../../components/Card/card';
import CommonAnalyticsCard from '../../components/CommonAnalyticsCard';
import { GET_DEVICE } from './graphql/Queries';

const DeviceData = ({ hide, hidden }) => {
  const { id } = useParams();
  const { getStartDate, getEndDate } = useContext(AppContext);

  const startDate = getStartDate();
  const endDate = getEndDate();

  const { data = {}, loading } = useQuery(GET_DEVICE, {
    fetchPolicy: 'network-only',
    variables: {
      linkId: id,
      startDate: startDate || '',
      endDate: endDate || '',
    },
  });

  const {
    getDevices: { count = '', totalCount = '', devices = [] } = {},
  } = data;

  const columns = [
    {
      title: 'Device',
      dataIndex: 'device',
      key: 'device',
      width: '30%',
    },
    {
      title: 'Visit',
      dataIndex: 'visit',
      key: 'visit',
      width: '25%',
      align: 'center',
    },
    {
      title: 'Visit %',
      dataIndex: 'visitpercent',
      key: 'visitpercent',
      render: (text) => (
        <div className="width-percent-95">
          <Progress
            percent={percentformat(text, totalCount)}
            size="small"
            status="normal"
            strokeColor={{
              '0%': '#e27969',
              '100%': '#303179',
            }}
          />
        </div>
      ),
    },
  ];

  const userdata = devices.map(
    ({ device_count: deviceCount = '', device = '' }) => ({
      key: device,
      device,
      visit: deviceCount,
      visitpercent: deviceCount,
    }),
  );

  return (
    <>
      <CommonAnalyticsCard
        loading={loading}
        label="Device"
        count={count}
        hidden={hidden}
      >
        <DesktopOutlined className="fontsize-28 orange_col" />
      </CommonAnalyticsCard>

      <div hidden={hide}>
        <Card title={`Device: ${count}`} className="mb-20">
          <Table
            dataSource={userdata}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </Card>
      </div>
    </>
  );
};

export default DeviceData;

import { Button as AntButton } from 'antd';
import { React } from 'react';
import './styles.less';

const Button = ({
  children,
  onClick,
  className,
  type,
  icon,
  shape,
  size,
  style,
  loading,
  block,
  disabled,
  hidden,
  htmlType = 'submit',
}) => (
  <AntButton
    htmlType={htmlType}
    block={block}
    loading={loading}
    className={className}
    onClick={onClick}
    type={type}
    icon={icon}
    shape={shape}
    size={size}
    style={style}
    disabled={disabled}
    hidden={hidden}
  >
    {children}
  </AntButton>
);
export default Button;

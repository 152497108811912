import { LinkOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Checkbox, Form, Input, message, Tabs } from 'antd';
import React, { useState } from 'react';
import Logo from '../../assets';
import linkList from '../../assets/linksList.png';
import workspace from '../../assets/workspace.png';
import { REGEX, TERMS_AND_SERVICES } from '../../common/constants';
import { copyToClipboard, formValidatorRules } from '../../common/utils';
import { CREATE_QUICK_LINK } from '../../modules/Link/graphql/Mutations';
import Button from '../Button/Button';
import './commonForm.less';

const { TabPane } = Tabs;

const CommonForm = ({ btnText, onFinish, loading }) => {
  const { required, email } = formValidatorRules;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [shortenUrl, setShortenUrl] = useState(null);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [
    validationTriggeredForShortLink,
    setValidationTriggeredForShortLink,
  ] = useState(false);
  const [createQuickLink] = useMutation(CREATE_QUICK_LINK, {
    onCompleted(res) {
      const { shortLink } = res?.createQuickLink;
      setShortenUrl(shortLink);
      setSubmitLoading(false);
    },
    onError() {
      setSubmitLoading(false);
    },
  });

  const createShortLink = (values) => {
    setSubmitLoading(true);
    createQuickLink({
      variables: {
        input: { ...values },
      },
    });
  };

  const handleUrlChange = (e) => {
    const {
      target: { value },
    } = e;

    if (!value) {
      setShortenUrl(null);
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onShortLinkFinishFailed = () => {
    setValidationTriggeredForShortLink(true);
  };

  return (
    <div className="main-form-wrapper">
      <div className="app-aside">
        <div className="d-flex align-center">
          <div className="glassmorphism project-img">
            <img alt="utags-dashboard" src={linkList} />
          </div>
          <div className="img-info ml-10">
            <span>
              Descriptive and memorable short names for all your long webpage
              address! Spend less time managing your links and more time
              optimizing their conversion with URLTags!
            </span>
          </div>
        </div>
        <div className="d-flex align-center mt-80">
          <div className="img-info mr-10">
            <span>
              Our campaign was amazing, but we just need a better way to track
              all the links.” Sounds like you? You’re not alone. With URLtags
              you will be able to
            </span>
            <ul>
              <li>Create bite-size, branded URLs</li>
              <li>Learn more about how your content is being consumed</li>
              <li>Get crucial insight into overall engagement of your links</li>
            </ul>
          </div>

          <div className="glassmorphism project-img">
            <img alt="utags-dashboard" src={workspace} />
          </div>
        </div>
      </div>
      <div className="app-form d-flex flex-vertical justify-center">
        <div className="logo-wrapper">
          <Logo className="logo" />
        </div>

        <div className="form-title">
          <Tabs>
            <TabPane
              icon={<LoginOutlined />}
              tab={<span>Sign In</span>}
              key="1"
            >
              <Form
                layout="vertical"
                validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item label="Email" name="email" rules={[required, email]}>
                  <Input suffix={<UserOutlined />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error('Should accept agreement'),
                            ),
                    },
                  ]}
                >
                  <Checkbox>
                    On checking this you accept our
                    <a
                      href={TERMS_AND_SERVICES}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      Terms and Conditions
                    </a>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="btn-Primary"
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  >
                    {btnText}
                  </Button>
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane
              icon={<LinkOutlined />}
              tab={<span>Shorten Your Link</span>}
              key="2"
            >
              <Form
                name="create-quick-link"
                validateTrigger={
                  validationTriggeredForShortLink ? 'onChange' : 'onSubmit'
                }
                onFinish={createShortLink}
                onFinishFailed={onShortLinkFinishFailed}
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  label="Link"
                  name="URL"
                  rules={[
                    {
                      required: true,
                      pattern: REGEX?.WEB_URL,
                      message: 'Enter valid URL',
                    },
                  ]}
                >
                  <Input
                    onChange={handleUrlChange}
                    allowClear
                    placeholder="https://yourlink.com"
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" loading={submitLoading}>
                    Create
                  </Button>
                </Form.Item>
              </Form>
              {shortenUrl && (
                <div className="mt-30">
                  <span className="shorten-url-labels">
                    Copy your short link
                  </span>
                  <div className="shorten-url-section d-flex justify-between align-center mt-10">
                    <span>
                      <a
                        href={`${process.env?.REACT_APP_GRAPHQL}${shortenUrl}`}
                        target="blank"
                      >{`${process.env?.REACT_APP_GRAPHQL}${shortenUrl}`}</a>
                    </span>
                    <Button
                      onClick={() => {
                        copyToClipboard(
                          `${process.env?.REACT_APP_GRAPHQL}${shortenUrl}`,
                        );
                        message?.success('Link copied to clipboard..!');
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Copy
                    </Button>
                  </div>
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default CommonForm;
